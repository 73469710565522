import { render, staticRenderFns } from "./CustomerSelect.vue?vue&type=template&id=7f15c87f&"
import script from "./CustomerSelect.vue?vue&type=script&lang=ts&"
export * from "./CustomerSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/tmp/workspace/SPP/spp-erp-fe-staging/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7f15c87f')) {
      api.createRecord('7f15c87f', component.options)
    } else {
      api.reload('7f15c87f', component.options)
    }
    module.hot.accept("./CustomerSelect.vue?vue&type=template&id=7f15c87f&", function () {
      api.rerender('7f15c87f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/custom/select/CustomerSelect.vue"
export default component.exports